.product-logo {
    height: 36px;
    width: 187px;
}

.product-name {
    font-size: 24px;
    font-family:  calibri,'source sans pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #990000;
    line-height: 15px;
    font-weight: 400;
}